/* eslint-disable import/no-cycle */
import Helpers from "./helpers";

const Config = {
  urls: {
    api: process.env.REACT_APP_PEANUT_BACKEND_URL,
    branch: {
      base: process.env.REACT_APP_BRANCH_BASE_URL,
      defaults: {
        "~feature": "direct",
        "~channel": "website",
        "~campaign": "website",
      },
    },
    store: {
      ios: "https://itunes.apple.com/us/app/peanut-moms-meet/id1178656034?mt=8",
      android:
        "https://play.google.com/store/apps/details?id=com.teampeanut.peanut",
    },
    shop: "https://shop.peanut-app.io",
  },
  paths: {
    home: "/",
    privacy: "/privacy",
    terms: "/terms",
    about: "/about-us",
    ttc: "/ttc",
    guidelines: "/community-guidelines",
    group: (id) => `/groups/${id}/:slug`,
    test: "/test",
    chrissy: "/motherhood/feeling-shitty--p24l-knr", // CHRISSY CODE
    posts: "/posts",
    post: (id) => `/posts/${id}`,
    sitemap: "/sitemap",
    robots: "/robots",
    jobs: "https://peanut.recruitee.com",
    starther: "/starther",
    download: "/download",
    download_motherhood: "/download/motherhood",
    download_ttc: "/download/ttc",
    blog: "/blog",
    blog_page: (id) => `/blog/page-${id}`,
    blog_tag_page: (params) => `/blog/tag/${params[0]}/page-${params[1]}`,
    blog_tag: (slug) => `/blog/tag/${slug}`,
    seo_blog: (id) => `/blog/${id}`,
    pregnancy: "/pregnancy-community",
    motherhood: "/motherhood-community",
    fertility: "/fertility-community",
    menopause: "/menopause-community",
    offers: "/peanut-offers",
    experts: "/experts",
  },
  emails: {
    contact: "info@teampeanut.com",
    advertising: "advertising@teampeanut.com",
    feedback: "feedback@teampeanut.com",
    press: "press@teampeanut.com",
    support: "support@teampeanut.com",
  },
  images: {
    fallback: (uri) => Helpers.absoluteUrl(uri, "/images/social-share.jpg"),
    fallbackUrl:
      "https://storage.googleapis.com/peanut-assets/images/social_share/social-share.jpg",
  },
  noindex: process.env.REACT_APP_NOINDEX === "YES",
  http: {
    scheme: process.env.REACT_APP_HTTP_SCHEME,
  },

  tracking: {
    branch_key: process.env.REACT_APP_BRANCH_KEY,
  },

  auth: {
    facebook: {
      app_id: process.env.REACT_APP_FB_APP_ID,
      client_token: process.env.REACT_APP_FB_CLIENT_TOKEN,
    },
  },

  tags: {
    pregnancy: 2,
    motherhood: 3,
    fertility: 37,
    menopause: 23,
  },

  placeholders: {
    communityPostDescription:
      "Read what women are talking about on Peanut, a safe space for women to meet and find support - fertility, pregnancy, and motherhood through to menopause",
  },
};

export default Config;
