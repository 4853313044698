import { CommunityPostComment } from "types/CommunityPostComment";
import objectKeysToCamelCase from "helpers/objectKeysToCamelCase";
import removeEmpty from "helpers/removeEmpty";
import { ApiCommunityPostComment } from "types/api/communityPostComment";

const transformCommunityPostComment = (
  apiCommunityPostcomment: ApiCommunityPostComment
): CommunityPostComment => {
  const data = objectKeysToCamelCase(apiCommunityPostcomment);

  data.likeCount = apiCommunityPostcomment.n_likes;
  data.replyCount = apiCommunityPostcomment.n_replies;

  data.author.displayName = data.author.displayName || data.author.firstName;

  if (!data.author.avatarUrl) {
    data.author.avatarUrl =
      "https://storage.googleapis.com/peanut-assets/images/pages_virtual_author_icons/incognito%402x.png";
  }

  delete data.nLikes;
  delete data.nReplied;

  return removeEmpty(data);
};

export default transformCommunityPostComment;
